import { getOrCreateDataObject } from 'o365.vue.ts';

export default getOrCreateDataObject({
    appId: "menu",
    id:'dsOMyRecentApps',
    viewName: 'sviw_System_MyRecentApps',
    maxRecords: 25,
    distinctRows: true,
    disableLayouts: true,
    filterString: 'MenuGroup IS NOT NULL',
    fields: [
        {
            name: 'HostName',
            type: 'string'
        },
        {
            name: 'IsNT',
            type: 'bit'
        },
        {
            name: 'ArticleID',
            type: 'string'
        },
        {
            name: 'MenuGroup',
            type: 'string'
        },
        {
            name: 'Title',
            type: 'string'
        },
        {
            name: 'Keywords',
            type: 'string'
        },
        {
            name: 'ID',
            type: 'number'
        },
        {
            name: 'Updated',
            type: 'date',
            sortOrder: 1,
            sortDirection: 'desc'
        },
        {
            name: "IsBookMarked",
            type: "bit",
        }
    ]
});